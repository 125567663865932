<template>
  <div>
    <span v-for="(contact, idx) in contacts" :key="contact.id">
      <router-link :to="$objectViewRoute(contact)">
        <template>
          {{ `${contact.firstname} ${contact.lastname} - ${contact.email}` }}
          {{ contacts.length - 1 > idx ? "," : " " }}<br />
        </template>
      </router-link>
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "contacts",

  components: {},

  mixins: [formMixin],

  props: {
    contacts: {
      type: Array,
      default: () => [],
      description: "Contacts",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    contacts(contacts) {},
  },
};
</script>
